<template>
  <div id="agents">
    <portal to="filter">
      <el-header class="filter-header">
        <div class="title">Agent List</div>
        <div class="actions-buttons">
          <el-button type="secondary" class="btn" :class="{ 'active-filter': filterActive ? true : false }" @click="toggle"><i class="icon-filter-filter"></i> Filters</el-button>
          <el-button type="primary" class="btn" @click="setItem(null)">
            <i class="el-icon-loading" style="font-size: 18px; color: white" v-if="loading == 'openAddDialogLoading'"></i>
            <i class="icon-filter-add" v-else></i>Add New Agent</el-button
          >
        </div>
      </el-header>
    </portal>
    <div class="filter-container">
      <div class="header">
        <div class="title">Filters</div>
        <el-button type="danger" class="btn" @click="clearFilter">
          <i class="icon-filter-close"></i>
          Clear Filter
        </el-button>
      </div>
      <div class="all-inputs">
        <el-row :gutter="36">
          <el-col :sm="8">
            <el-input placeholder="Search Agent Name" prefix-icon="el-icon-search" v-model="filter.SearchTerm" clearable></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="36">
          <el-col class="input-container" :sm="8">
            <div class="text none">Active or Deactive</div>
            <el-select v-model="filter.GetDeactivated" clearable @clear="filter.GetDeactivated = null" placeholder="Select Role">
              <el-option label="All" :value="null"> </el-option>
              <el-option label="Only Actives" :value="false"> </el-option>
              <el-option label="Only Deactives" :value="true"> </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="table-container">
      <div class="view-info">
        <p>You are viewing page {{ filter.Page }} out of a total of {{ totalCount.PageCount }} pages. (Total Records: {{ totalCount.TotalCount }})</p>
        <el-button class="refresh-btn" @click="arrowsRotate"> <i class="icon-arrows-rotate-solid"></i></el-button>
      </div>
      <el-table v-if="agentList" :data="agentList" style="width: 100%">
        <el-table-column prop="AgentNo" label="ID" width="180"> </el-table-column>
        <el-table-column prop="Title" label="Agent Name"> </el-table-column>
        <el-table-column prop="AuthorizedPerson" label="Authorized Person"> </el-table-column>
        <el-table-column prop="Address" label="Location">
          <template slot-scope="scope">
            <span style="text-transform: none" v-if="getAllCountries.some((x) => x.country_id == scope.row.CountryId)">{{ getAllCountries.find((x) => x.country_id == scope.row.CountryId).cities.find((x) => x.city_id == scope.row.CityId).name }}, </span>
            <span style="text-transform: none" v-if="getAllCountries.some((x) => x.country_id == scope.row.CountryId)">{{ getAllCountries.find((x) => x.country_id == scope.row.CountryId).country }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="Date">
          <template slot-scope="scope">
            <span>Created Date : {{ $moment(scope.row.CreatedDateTime).format("DD-MM-YYYY") }}</span
            ><br />
            <span>Last Update : {{ $moment(scope.row.UpdatedDateTime).format("DD-MM-YYYY") }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="IsDeactivated" label="Active or Deactive">
          <template slot-scope="scope">
            <div class="status danger" v-if="scope.row.IsDeactivated == true">
              <span class="danger"> Deactive</span>
            </div>
            <div class="status success" v-if="scope.row.IsDeactivated == false">
              <span class="success"> Active</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Actions" width="160">
          <template slot-scope="scope">
            <el-dropdown>
              <el-button type="primary"> Actions<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span class="view-edit" @click="setItem(scope.row.ID)"><i class="icon-pen-to-square-solid"></i> View & Edit</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="scope.row.IsDeactivated == false">
                  <span class="delete-item" @click="toggleAgent(scope.row.ID)"><i class="icon-ban-solid"></i>Deactive</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="scope.row.IsDeactivated == true">
                  <span class="confirm-quatation" @click="toggleAgent(scope.row.ID)"><i class="icon-check-double-solid"></i>Active</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-bottom">
        <div class="info">
          <span>Show</span>
          <el-select v-model="filter.PageSize">
            <el-option label="12" :value="12"></el-option>
            <el-option label="24" :value="24"></el-option>
            <el-option label="36" :value="36"></el-option>
            <el-option label="100" :value="100"></el-option>
            <el-option label="200" :value="200"></el-option>
          </el-select>
          <span>entries</span>
        </div>
        <el-pagination layout="prev, pager, next" :total="totalCount.TotalCount"> </el-pagination>
      </div>
    </div>
    <el-dialog top="5vh" :visible.sync="agentDialogVisible" :destroy-on-close="true" :show-close="false" :close-on-click-modal="false" :before-close="clearValidation">
      <addOrUpdateAgentsDialog :id="setId" @close="closeAddDialog()" v-if="agentDialogVisible" />
    </el-dialog>
  </div>
</template>

<script>
import addOrUpdateAgentsDialog from "../../components/addOrUpdateAgentsDialog.vue";
export default {
  components: {
    addOrUpdateAgentsDialog,
  },
  data() {
    return {
      filterActive: false,
      setId: null,
      agentDialogVisible: null,
      loading: false,
      timeout: null,
    };
  },
  mounted() {
    this.jQuery(".filter-container").hide();
  },
  async beforeMount() {
    this.filter.GetDeactivated = null;

    await this.$store.dispatch("getAgentList", this.filter);
  },

  computed: {
    filter() {
      return this.$store.getters.getAgentFilter;
    },
    totalCount() {
      return this.$store.getters.getAgentTotal;
    },
    agentList() {
      return this.$store.getters.getAgentList;
    },
    getAllCountries() {
      return this.$store.getters.getAllCountries;
    },
  },

  methods: {
    async toggleAgent(Id) {
      var payload = {
        ID: Id,
      };

      this.$confirm("Are you sure active or deactive this agent?", "Warning", {
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Agent/DeactivateAgentToggle", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            await this.$store.dispatch("getAgentList");
          }
        })
        .catch(() => {});
    },
    arrowsRotate() {
      this.$store.dispatch("getAgentList");
    },
    toggle() {
      this.filterActive = !this.filterActive;
      this.jQuery(".filter-container").slideToggle();
    },
    closeAddDialog(id) {
      this.setId = id;
      this.agentDialogVisible = false;
    },
    setItem(item) {
      if (item != null) {
        this.loading = "openAddDialogLoading" + item;
      } else {
        this.loading = "openAddDialogLoading";
      }
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.setId = item;
        this.$store.commit("setAgentValidation", []);
        this.agentDialogVisible = true;
        this.loading = false;
      }, 500);
    },
    clearValidation() {
      this.$store.commit("setAgentValidation", []);
      this.agentDialogVisible = false;
    },
    clearFilter() {
      this.filter.SearchTerm = null;
      this.filter.GetDeactivated = null;
    },
    deleteFile(Id) {
      this.$store.dispatch("deleteOneAgent", { Id });
    },
    develop() {
      this.$message.info("Bu özellik geliştirme aşamasındadır...");
      return;
    },
  },
  watch: {
    filter: {
      deep: true,
      handler: async function () {
        await this.$store.dispatch("getAgentList", this.filter);
      },
    },
  },
};
</script>

<style></style>
