<template>
  <div class="dialog-container">
    <transition name="el-fade-in" mode="out-in">
      <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'addUpdateScreen' && agentForm == null" key="screen-loading">
        <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
      </el-row>
      <el-row v-else key="content-loading">
        <div class="dialog-title">
          <div class="head-title">{{ agentForm.AgentNo != null ? "Agent" : "New Agent" }}<span class="yellow-dot">.</span>{{ agentForm.AgentNo != null ? "  #" + agentForm.AgentNo : " " }}</div>
          <el-button class="btn close" type="danger" @click="close()"> <i class="icon-xmark-solid"></i></el-button>
        </div>
        <div class="form">
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Agent Name</div>
              <el-input v-model="agentForm.Title" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("Title", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Authorized Person</div>
              <el-input v-model="agentForm.AuthorizedPerson" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("AuthorizedPerson", validation) }}</span>
            </el-col>
            <el-col class="input-container email" :sm="8">
              <div class="text">Email</div>
              <el-input v-model="agentForm.Email" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("Email", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Contact Number</div>
              <el-input v-model="agentForm.ContactNumber" placeholder="07776668899"></el-input>
              <span class="error-validation">{{ $errorMessage("ContactNumber", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Delivery Type</div>
              <el-input v-model="agentForm.DeliveryType" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("DeliveryType", validation) }}</span>
            </el-col>
          </el-row>

          <div class="line"></div>
          <div class="title">Address<span class="yellow-dot">.</span></div>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="24">
              <div class="text">Address</div>
              <el-input v-model="agentForm.Address" type="textarea" :rows="5" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("Address", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Country</div>
              <el-select
                v-model="agentForm.CountryId"
                filterable
                v-if="getAllCountries"
                placeholder="Select"
                @clear="
                  agentForm.CountryId = null;
                  agentForm.CityId = null;
                "
                clearable
              >
                <el-option v-for="(item, index) in getAllCountries" :key="item.country_id + index" :label="item.country" :value="item.country_id"> </el-option>
              </el-select>
              <span class="error-validation">{{ $errorMessage("CountryId", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">City</div>
              <el-select v-model="agentForm.CityId" filterable placeholder="Select" @clear="agentForm.CityId = null" clearable :disabled="agentForm.CountryId == null" v-if="getAllCountries">
                <template v-if="getAllCountries.some((x) => x.country_id == agentForm.CountryId)">
                  <el-option v-for="item in getAllCountries.find((x) => x.country_id == agentForm.CountryId).cities" :key="item.city_id" :label="item.name" :value="item.city_id"> </el-option>
                </template>
              </el-select>
              <span class="error-validation">{{ $errorMessage("CityId", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Post Code</div>
              <el-input class="postcode" v-model="agentForm.PostCode" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("PostCode", validation) }}</span>
            </el-col>
          </el-row>

          <div class="form" style="margin-top: 0" v-if="agentForm.ID">
            <div class="line"></div>
            <div class="dialog-container"></div>
          </div>
          <div class="title">Notes<span class="yellow-dot">.</span></div>
          <el-input v-model="agentForm.Note" type="textarea" :rows="5" placeholder="Add Note..."></el-input>
        </div>
        <div class="dialog-footer footerbutton">
          <el-button class="btn" type="danger" @click="close()"> <i class="icon-xmark-solid"></i> Cancel</el-button>
          <el-button class="btn" @click="addOrUpdate" :type="agentForm.ID != null ? 'info' : 'primary'" :loading="loading == 'saveLoading'" :icon="agentForm.ID != null ? 'el-icon-edit' : 'el-icon-plus'">{{ agentForm.ID != null ? "Edit Agent" : "Add New Agent" }}</el-button>
        </div>
      </el-row>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["id"],
  name: "addOrUpdateAgentsDialog",

  data() {
    return {
      loading: false,
      timeout: null,
      agentForm: null,
      // agentForm: {
      //   ID: null,
      //   AgentNo: null,
      //   Title: null,
      //   AuthorizedPerson: null,
      //   Email: null,
      //   ContactNumber: null,
      //   Address: null,
      //   CountryId: 2635167,
      //   CityId: null,
      //   PostCode: null,
      //   Note: null,
      // },
    };
  },
  async created() {
    await this.checkedId();
    this.$store.commit("setAgentValidation", []);
    if (this.id) {
      await this.setForm();
    } else {
      this.agentForm = {
        ID: null,
        Title: null,
        AgentNo: null,
        AuthorizedPerson: null,
        Email: null,
        ContactNumber: null,
        Address: null,
        CountryId: 2635167,
        CityId: null,
        PostCode: null,
        Note: null,
      };
    }
    await this.setNumber();
  },
  beforeDestroy() {
    this.agentForm = {
      ID: null,
      Title: null,
      AuthorizedPerson: null,
      Email: null,
      AgentNo: null,
      ContactNumber: null,
      Address: null,
      CountryId: 2635167,
      CityId: null,
      PostCode: null,
      Note: null,
    };
  },
  computed: {
    validation() {
      return this.$store.getters.getAgentValidationErrors;
    },
    getAllCountries() {
      return this.$store.getters.getAllCountries;
    },
  },
  methods: {
    close(id) {
      this.$emit("close", id);
    },
    async setForm() {
      this.agentForm = await this.$store.dispatch("getOneAgent", this.id);
    },
    setNumber() {
      if (this.agentForm.ContactNumber) {
        this.CountryCode = this.agentForm.ContactNumber.slice(0, -10);
        this.CustomNumber = this.agentForm.ContactNumber.slice(-10);
      } else {
        this.CountryCode = null;
        this.CustomNumber = null;
      }
    },
    async addOrUpdate() {
      this.$store.commit("setAgentValidation", []);
      if (this.validation.length > 0) {
        return;
      }
      this.loading = "saveLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("addUpdateAgent", this.agentForm);

        if (!res.HasError) {
          this.agentForm = {
            ID: null,
            Title: null,
            AuthorizedPerson: null,
            Email: null,
            ContactNumber: null,
            Address: null,
            AgentNo: null,
            CountryId: 2635167,
            CityId: null,
            PostCode: null,
            Note: null,
          };
          await this.$store.dispatch("getAgentList");
          this.close();
        }
        this.loading = false;
      }, 500);
    },
    async checkedId() {
      this.loading = "addUpdateScreen";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        if (this.id) {
          await this.$store.dispatch("getAgentList");
        } else {
          this.agentForm = {
            ID: null,
            Title: null,
            AgentNo: null,
            AuthorizedPerson: null,
            Email: null,
            ContactNumber: null,
            Address: null,
            CountryId: 2635167,
            CityId: null,
            PostCode: null,
            Note: null,
          };
        }
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style></style>
